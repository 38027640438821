<template>
    <div>
        <ValidationObserver ref="msgForm">
            <b-row>
                <b-col cols="12" md="4">
                    <ValidationProvider name="type" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('type')">
                            <multi-selectbox
                                v-model="msgForm.type"
                                :options="typeOptions"
                                :multiple="false"
                                :validateError="errors[0]"
                            ></multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="3" v-if="inputVif(typeConditions[0], msgForm.type)">
                    <ValidationProvider name="course" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('course')">
                            <multi-selectbox
                                v-model="msgForm.course_code"
                                :options="courseOptions"
                                :multiple="false"
                                :validateError="errors[0]"
                            ></multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="3" v-if="inputVif(typeConditions[1],msgForm.type)">
                    <ValidationProvider name="class_id" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('class')">
                            <multi-selectbox
                                v-model="msgForm.class_id"
                                :options="classOptions"
                                :multiple="false"
                                :validateError="errors[0]"
                            ></multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="receivers" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('receivers')">
                            <multiselect
                                :placeholder="$t('select')"
                                v-model="msgForm.receivers"
                                :options="receiverOptions"
                                :class="errors[0] != null ? 'box-border-color' : ''"
                                label="text"
                                track-by="value"
                                :multiple="true"
                                :close-on-select="false"
                                :clear-on-select="false"
                                :select-label="''"
                                :selected-label="''"
                                :deselect-label="''">
                                <template slot="selection" slot-scope="{ values, search, isOpen}" v-if="true">
                                    <span v-if="values.length && !isOpen" class="multiselect__single">
                                        {{ values.length }} {{ $t('person') }}
                                    </span>
                                </template>
                            </multiselect>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col class="mt-2" cols="12" md="1">
                    <b-button class="mt-4" variant="primary" block @click="addToReceivers">{{ $t('add') }}</b-button>
                </b-col>
                <b-col cols="12">
                    <hr>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$t('to')">
                        <div style="min-height: 40px;" class="border mb-2 rounded p-1 overflow-y-scroll overflow-x-hidden" :class="toValid ? 'box-border-color' : ''">
                            <h6>
                                <b-badge v-for="(itm,key) in msgForm.to" :key="key" class="mr-1 mb-1" variant="info">
                                    <span class="zoom-cursor" @click="showReceivers(itm.course_code.text,key)" v-if="itm.course_code">{{ itm.course_code.text }}</span>
                                    <span class="zoom-cursor" @click="showReceivers(itm.class_id.text,key)" v-else-if="itm.class_id">{{ itm.class_id.text }}</span>
                                    <span class="zoom-cursor" @click="showReceivers(itm.type.text,key)" v-else-if="itm.type">{{ itm.type.text }}</span>
                                    &nbsp;<i @click="deleteSelected(key)" style="color:#cccccc;" class="ri-close-circle-line"></i>
                                </b-badge>
                            </h6>
                        </div>
                        <b-form-invalid-feedback v-if="toValid" v-html="$t('this_field_is_required')"/>
                    </b-form-group>
                </b-col>
                <b-col cols="10">
                    <ValidationProvider name="subject" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('subject')">
                            <b-form-input type="text" v-model="msgForm.subject"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="2">
                    <ValidationProvider name="is_lock" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('answerable')">
                            <b-form-checkbox v-model="msgForm.is_lock" name="check-button" switch size="lg">
                                {{ msgForm.is_lock == true ? $t('open') : $t('closed') }}
                            </b-form-checkbox>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="message" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('message')">
                            <ck-editor-input
                                v-model="msgForm.message"
                                :validateError="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="file" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('file')">
                            <div class="d-flex custom-file-upload">
                                <b-form-file
                                    multiple="multiple"
                                    v-model="msgForm.file"
                                    :state="errors[0] ? true : null"
                                    :placeholder="$t('select_file')"
                                    :drop-placeholder="$t('drop_file')"
                                    ref="fileInput">

                                </b-form-file>
                                <b-button variant="outline-secondary"
                                          @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse') }}
                                </b-button>
                            </div>
                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <b-button @click="send" variant="primary">{{ $t('send') }}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
        <CommonModal ref="badgeReceivers" size="md">
            <template v-slot:CommonModalTitle><i class="ri-user-star-line top-plus-2 mr-2"></i> {{ badgeReceiverTitle.toUpper() }}</template>
            <template v-slot:CommonModalContent>
                <div>
                    <div class="row">
                        <div class="col-12" v-if="msgForm.to[badgeReceiverIndex]">
                            <b-alert v-for="(itm,key) in msgForm.to[badgeReceiverIndex].receivers" :key="key" class="d-flex justify-content-between" show variant="info">
                                <span>{{ itm.text }}</span>
                                <i @click="deleteFromBadgeReceivers(key)" style="color:#ff8080;" class="ri-close-circle-fill top-plus-2 mr-2"></i>
                            </b-alert>
                        </div>
                    </div>
                </div>
            </template>
        </CommonModal>
    </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate"
import CkEditorInput from "@/components/elements/CkEditorInput";
import MessageService from "@/services/MessageService";
import ParameterService from '@/services/ParameterService';
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"
import qs from "qs";
import CommonModal from "@/components/elements/CommonModal";

export default {
    props: {},
    components: {
        ValidationProvider,
        ValidationObserver,
        CkEditorInput,
        MultiSelectbox,
        CommonModal

    },

    data() {
        return {
            msgForm: {
                to: [],
                type: null,
                subject: null,
                message: null,
                course_code: null,
                class_id: null,
                receivers: null,
                is_lock: true,
                file: []
            },

            typeOptions: [],
            courseOptions: [],
            classOptions: [],
            rawData: [],
            receiverOptions: [],
            typeConditions: [
                'course_code',
                'class_id',
                'count'
            ],
            badgeReceiverTitle: '',
            badgeReceiverIndex: 0,
            toValid: false


        }
    },
    created() {
        this.getReceivers()
    },
    watch: {
        'msgForm.type': function (value) {
            this.setReceiverOptions(value)
        },
        'msgForm.course_code': function (value) {
            this.setReceiverOptions(this.msgForm.type)
        },
        'msgForm.class_id': function (value) {
            this.setReceiverOptions(this.msgForm.type)
        },
        'msgForm.to': function (value) {
            if (value.length > 0) {
                this.toValid = false
            }
        }
    },
    methods: {
        getReceivers() {
            MessageService.getGroups().then(response => {
                this.rawData = response.data.data
                this.getTypeOptions(this.rawData)
                this.setOptions(this.rawData)
            }).catch(e => {})
        },

        getTypeOptions(rawData) {
            const config = {
                params: {
                    sort: 'name',
                    limit: -1,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            ParameterService.getItems('message_groups', config).then(response => {
                let data = response.data.data.items
                let allowedData = Object.keys(rawData)
                data.forEach(itm => {
                    if (allowedData.includes(itm.code)) {
                        this.typeOptions.push({
                            value: itm.code,
                            text: this.getLocaleText(itm, 'name'),
                            condition: Object.keys(rawData[itm.code][0]).filter(c => {
                                if (this.typeConditions.includes(c) == true) {
                                    return c
                                }
                            }).toString()
                        })
                    }
                })
            })
        },

        setOptions(source) {
            Object.keys(source).forEach(obj => {
                source[obj].forEach(itm => {
                    if ('course_code' in itm) {
                        this.courseOptions.push({
                            value: itm.course_code,
                            text: this.getLocaleText(itm, 'course_name'),
                            section_id: itm.section_id
                        })
                    }
                    else if ('class_id' in itm) {
                        this.classOptions.push({
                            value: itm.class_id,
                            text: itm.level + itm.class,
                        })
                    }
                })
            })
        },

        setReceiverOptions(selected) {
            this.receiverOptions = []
            this.msgForm.receivers = null
            let condition = []
            this.typeOptions.forEach(to => {
                if (to.value == selected) {
                    condition.push(to.condition)
                }
            })

            if (selected) {
                this.rawData[selected].forEach(rd => {
                    if (this.msgForm[condition[0]]) {
                        if (rd[condition[0]] == this.msgForm[condition[0]]) {
                            rd.receivers.forEach(rec => {
                                this.receiverOptions.push({
                                    value: rec.user_id,
                                    text: rec.full_name
                                })
                            })
                        }
                    }
                    else {
                        rd.receivers.forEach(rec => {
                            this.receiverOptions.push({
                                value: rec.user_id,
                                text: rec.full_name
                            })
                        })
                    }
                })
            }

        },

        inputVif(mod, type) {
            let result = []
            if (type != null) {
                this.typeOptions.forEach(itm => {
                    if (itm.value == type && itm.condition == mod) {
                        result.push(itm.condition)
                    }
                })
            }
            return result.length > 0 ? true : false
        },

        deleteSelected(index) {
            this.msgForm.to.splice(index, 1)
        },

        addToReceivers() {
            if (this.msgForm.type == null) {
                this.$toast.error(this.$t('type') + this.$t('x_field_is_required'))
            }
            else if (this.inputVif(this.typeConditions[0], this.msgForm.type) && this.msgForm.course_code == null) {
                this.$toast.error(this.$t('course') + this.$t('x_field_is_required'))
            }
            else if (this.inputVif(this.typeConditions[1], this.msgForm.type) && this.msgForm.class_id == null) {
                this.$toast.error(this.$t('class') + this.$t('x_field_is_required'))
            }
            else {
                let addData = {
                    type: this.typeOptions.filter(c => c.value == this.msgForm.type)[0],
                    course_code: this.msgForm.course_code != null ? this.courseOptions.filter(c => c.value == this.msgForm.course_code)[0] : null,
                    class_id: this.msgForm.class_id != null ? this.classOptions.filter(c => c.value == this.msgForm.class_id)[0] : null,
                    receivers: this.msgForm.receivers == null ? this.receiverOptions : this.msgForm.receivers,
                    label: (this.msgForm.type + this.msgForm.class_id + this.msgForm.course_code).replaceAll(' ').toString()
                }

                if (this.checkReceiversData(addData)) {
                    this.msgForm.to.push(addData)
                    this.msgForm.type = null
                    this.msgForm.course_code = null
                    this.msgForm.class_id = null
                    this.msgForm.receivers = null
                }
                else {
                    this.$toast.error(this.$t('receivers_id_added_before'))
                }
            }
        },

        checkReceiversData(addData) {
            let result = []
            this.msgForm.to.forEach(toD => {
                if (addData.label === toD.label) {
                    result.push(toD)
                }
            })

            return result.length > 0 ? false : true
        },

        parseReceivers(data) {
            let result = []
            data.forEach(itm => {
                if (itm.course_code) {
                    result.push(itm.course_code.text)
                }
                else if (itm.class_id) {
                    result.push(itm.class_id.text)
                }
                else {
                    result.push(itm.type.text)
                }
            })

            return result.join(',')
        },

        parseDataArray(data) {
            let result = []
            data.forEach(itm => {
                let user_ids = []
                itm.receivers.forEach(rec => {
                    user_ids.push(rec.value)
                })
                result.push({
                    type: itm.type.value,
                    to: itm.course_code ? itm.course_code.section_id : itm.class_id ? itm.class_id.value : null,
                    user_ids: user_ids
                })
            })

            return JSON.stringify(result)
        },

        async send() {
            const isValid = await this.$refs.msgForm.validate();
            if (isValid && this.msgForm.to.length > 0) {


                let formData = new FormData
                formData.append('subject', this.msgForm.subject)
                formData.append('message', this.msgForm.message)
                formData.append('to', this.parseReceivers(this.msgForm.to))
                formData.append('data', this.parseDataArray(this.msgForm.to))
                formData.append('is_lock', this.msgForm.is_lock ? 0 : 1)
                this.msgForm.file.forEach((itm, index) => {
                    formData.append('files[' + index + ']', itm)
                })

                MessageService.store(formData).then(response => {
                    this.$toast.success(this.$t('success_send_message'))
                    this.$emit('closeBox', true);
                }).catch(e => {
                    this.$toast.error(this.$t('api.' + e.data.message))
                })
            }
            else {
                if (this.msgForm.to.length < 1) {
                    this.toValid = true
                }
            }
        },

        showReceivers(title, index) {
            this.badgeReceiverTitle = title
            this.badgeReceiverIndex = index
            this.$refs.badgeReceivers.$refs.commonModal.show()
        },

        deleteFromBadgeReceivers(index) {
            this.msgForm.to[this.badgeReceiverIndex].receivers.splice(index, 1)
            if (this.msgForm.to[this.badgeReceiverIndex].receivers.length < 1) {
                this.deleteSelected(this.badgeReceiverIndex)
                this.$refs.badgeReceivers.$refs.commonModal.hide()
            }
        }
    }
}
</script>
<style>
.zoom-cursor {
    cursor: zoom-in;
}
</style>

